import axios from "axios";

export default (url = "https://gitlab.mi.hdm-stuttgart.de/") => {
    const tokenString = "glpat-pZ8ScsWXXraKbozYUgLp"
    //TODO: New Key -> push with secret
    return axios.create({
        baseURL: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenString}`,
}
    })
}